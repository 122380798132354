import React, { Fragment, useState } from 'react';
import { get, chunk } from 'lodash';
import withRouter from 'react-router/withRouter';
import { slugify } from '../../../../../utils/string_util';

import loadable from '@loadable/component';

const Ibd2022Layout = loadable(
  () => import('../../../../fc-components/main/containers'),
  {
    resolveComponent: components => components.Ibd2022Layout
  }
);
const Button = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Button
  }
);
const IconTitle = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.IconTitle
  }
);
const Image = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Image
  }
);
const Section = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Section
  }
);
const Text = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Text
  }
);
const ArticleList = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.ArticleList
  }
);
const Footer = loadable(() => import('../../../../components/footer/footer'));

const IbdApplyPage = props => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onWayPointEnter = index => {
    setActiveIndex(index);
    // get the element from the left rail and its position on the page
    const leftRailEle = document.getElementById(`articleList__item-${index}`);
    const offset = leftRailEle.offsetTop;
    // tell its parent scrolling div to scroll to it
    const leftRailScroll = document.getElementById(`articleList__inner`);
    leftRailScroll.scrollTo({
      top: offset - 116, // bc of the padding-top
      behavior: 'smooth'
    });
  };

  const sections = [
    { sectionTitle: 'The 2024 Awards' },
    { sectionTitle: 'Why Enter' },
    { sectionTitle: 'Categories' },
    { sectionTitle: 'Past Honoree Testimonials' },
    { sectionTitle: 'Apply Now' }
  ];

  const quotes = [
    {
      quote: `"It meant so much to be recognized by Fast Company alongside some of the most innovative design companies in the world. Being honored by the Innovation by Design Awards wasn't just a great accolade for our team, but a sincere validation of what we've been working toward for years as a creative agency that works with purpose-driven organizations."`,
      name: 'Ben Ostrower',
      caption: 'Founder, Wide Eye'
    },
    {
      quote: `"Being given an Innovation by Design Award from Fast Company was a distinct honor. As a nonprofit, our work for the most vulnerable populations is often looked over. Having it recognized among some of the greatest companies and projects in the world was incredible validation for our team and supporters who believe so deeply in our work!"`,
      name: 'Sarah Lee',
      caption: 'COO, New Story'
    }
  ];

  const fullPackageObj = get(props, 'franchisePage', null);
  const packageData = get(fullPackageObj, 'data', {});
  const hideJudgeSection =
    packageData?.acf?.application_page?.hide_judges || false;
  const packageJudges = get(packageData, 'acf.application_page.judges', []);
  // const packageTitle = get(packageData, 'acf.title', '#');
  const packageBlurb = get(packageData, 'acf.franchise_blurb', '');
  const pastHonoreesTestimonialsText = get(sections, '[2].text', '');
  const acceptingApplications = get(
    packageData,
    'acf.application_page.acceptingApplications',
    undefined
  );
  const franchiseContributors = get(
    packageData,
    'acf.franchiseContributors',
    '#'
  );
  const packageDeadline = get(
    packageData,
    'acf.application_page.deadline',
    undefined
  );
  const packageApplyUrl = get(
    packageData,
    'acf.application_page.applyUrl',
    '#'
  );
  const packageEligibilityBlurb = get(
    packageData,
    'acf.application_page.eligibility',
    ''
  );
  const packageButtonText = get(
    packageData,
    'acf.application_page.buttonText',
    '#'
  );
  const packageCategories = get(
    packageData,
    'acf.application_page.categories',
    []
  );
  const packageCategoriesDescription = get(
    packageData,
    'acf.application_page.categories[0].categoryGroup.description',
    '#'
  );
  const packageWhyEnter = get(packageData, 'acf.application_page.whyApply', []);
  const whyEnterColumns = chunk(
    packageWhyEnter,
    (packageWhyEnter.length + 1) / 2
  );
  const franchiseBlurbImage = get(
    packageData,
    'acf.franchise_images.franchise_blurb_image',
    '#'
  );
  const franchiseFeatureImageDesktop = get(
    packageData,
    'acf.franchise_images.franchise_hero_image_desktop'
  );

  return (
    <Ibd2022Layout>
      <div className="ibdGridLayout">
        <div className="ibdGridLayout__left">
          <ArticleList
            articles={sections}
            name="ibd_anniversary"
            image="https://assets.fastcompany.com/image/upload/v1641404540/circle_2x.webp"
            activeIndex={activeIndex}
          />
        </div>
        <div className="ibdGridLayout__right">
          <Section
            name="topHero"
            maxWidth={860}
            bgColor="#000000"
            // position={0}
            // onWayPointEnter={onWayPointEnter}
          >
            <div className="topItems">
              <div className="item1">
                {/* <Text as="h1" color="#FFFFFF">
                  <h1 dangerouslySetInnerHTML={{ __html: packageTitle }} />
                </Text> */}
                <Image image={franchiseBlurbImage} width="315px" />
                <Text as="h2" color="#FFFFFF">
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: franchiseContributors
                    }}
                  />
                </Text>
                <div className="item1_packageDeadline">
                  <Text as="h2" color="#6C6C6C">
                    {packageDeadline}
                  </Text>
                  {acceptingApplications && (
                    <a href={packageApplyUrl}>
                      <Button
                        rounded
                        bgColor="#004FDF"
                        labelColor="#FFFFFF"
                        labelHoverColor="#ffffff"
                        label={packageButtonText}
                      />
                    </a>
                  )}
                </div>
              </div>
              <div className="item2">
                <Image image={franchiseFeatureImageDesktop} width="598px" />
              </div>
            </div>
          </Section>

          <Section
            name="blurb"
            maxWidth={860}
            position={0}
            onWayPointEnter={onWayPointEnter}
            slug={slugify('the 2024 awards')}
          >
            <IconTitle
              addClass="the_2022_awards"
              title="The 2024 Awards"
              color="#000000"
            />
            <Text as="div">
              <div dangerouslySetInnerHTML={{ __html: packageBlurb }} />
            </Text>
            {acceptingApplications && (
              <a href={packageApplyUrl}>
                <Button
                  bgColor="#004FDF"
                  labelHoverColor="#ffffff"
                  rounded
                  label={packageButtonText}
                />
              </a>
            )}
            <div className="deadlineSubText">
              <Text as="h6" color="#000000">
                {packageDeadline}
              </Text>
            </div>
          </Section>

          <Section
            name="whyEnter"
            maxWidth={860}
            bgColor="#ffffff"
            position={1}
            onWayPointEnter={onWayPointEnter}
            slug={slugify('why enter')}
          >
            <div className="sectionHeading">
              <IconTitle
                className="why_enter"
                title="Why Enter"
                color="#000000"
                // underline
                // underlineColor="#cccccc"
              />

              {/* <Section maxWidth={860} vMargins="20"> */}
              {/* <div className="sectionHeading">
                <IconTitle title="ELIGIBILITY" underline />
              </div> */}
              <Text as="div">
                <div
                  className="package_eligibility_blurb"
                  dangerouslySetInnerHTML={{
                    __html: packageEligibilityBlurb
                  }}
                />
              </Text>
              {acceptingApplications && (
                <a href={packageApplyUrl}>
                  <Button
                    bgColor="#004FDF"
                    labelHoverColor="#ffffff"
                    rounded
                    label={packageButtonText}
                  />
                </a>
              )}
              <div className="deadlineSubText">
                <Text as="h6" color="#000000">
                  {packageDeadline}
                </Text>
              </div>
            </div>
            <div className="reasonContainer">
              {whyEnterColumns.map((column, i) => (
                <div key={`whyEnter-column-${i}`} className="whyEnter-column">
                  {column.map((reason, index) => (
                    <div key={`whyEnter-${index}`} className="whyEnterItem">
                      <div className="icon">
                        <Image image={get(reason, 'icon.url')} />
                      </div>
                      <div>
                        <Text as="h3" color="#000000">
                          {reason.title}
                        </Text>
                        <Text
                          fontFamily="'CentraNo2Book', sans-serif"
                          fontSize="15px"
                          lineHeight="17px"
                          as="div"
                          color="#cccccc"
                        >
                          {reason.description}
                        </Text>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Section>

          <Section
            name="categories"
            maxWidth={860}
            bgColor="#000000"
            position={2}
            onWayPointEnter={onWayPointEnter}
            slug={slugify('categories')}
          >
            <div className="sectionHeading">
              <IconTitle
                className="categories"
                title="Categories"
                color="#FFFFFF"
              />
            </div>
            <Text as="div" color="#FFFFFF">
              <div
                dangerouslySetInnerHTML={{
                  __html: packageCategoriesDescription
                }}
              />
            </Text>
            {acceptingApplications && (
              <a href={packageApplyUrl}>
                <Button
                  bgColor="#004FDF"
                  labelHoverColor="#ffffff"
                  rounded
                  label={packageButtonText}
                />
              </a>
            )}
            <div className="deadlineSubText">
              <Text as="h6" color="#FFFFFF">
                {packageDeadline}
              </Text>
            </div>

            <div>
              {packageCategories.map((category, index) => {
                const categoryGroup = get(category, 'categoryGroup', {});
                const categoryName = get(categoryGroup, 'name', ''); // eslint-disable-line
                const categoryItems = get(categoryGroup, 'items', []);

                return (
                  <div
                    key={`category-container-${index}`}
                    className="categoryContainer"
                  >
                    {categoryItems.map((item, i) => (
                      <div
                        key={`category-item-${i}`}
                        className={`category-item-${item.title.replace(
                          /[!()-:+ ]/g,
                          ''
                        )}`}
                      >
                        <Text as="h3" color="#FFFFFF">
                          {item.title}{' '}
                          {item.subHed ? <span>{item.subHed}</span> : ''}
                        </Text>
                        <Text
                          fontFamily="'CentraNo2Book', sans-serif"
                          fontSize="15px"
                          lineHeight="17px"
                          as="div"
                          color="#CCCCCC"
                        >
                          {item.description}
                        </Text>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
            {acceptingApplications && (
              <div className="secondBtn">
                <a href={packageApplyUrl}>
                  <Button
                    bgColor="#004FDF"
                    labelHoverColor="#ffffff"
                    rounded
                    label={packageButtonText}
                  />
                </a>
              </div>
            )}
            <div className="deadlineSubText">
              <Text as="h6" color="#D7D7D7">
                {packageDeadline}
              </Text>
            </div>
          </Section>

          {/* Past judges */}
          {!hideJudgeSection &&
            packageJudges?.length > 0 && (
              <Section maxWidth={860}>
                <div className="sectionHeading">
                  <IconTitle title="Judges" />
                </div>
                <div className="judgesContainer">
                  {packageJudges.map((judge, i) => (
                    <div key={`judge-${i}`} className="judge-item">
                      <Image image={judge.image.url} />
                      <div className="judge-item__name">
                        <Text
                          as="div"
                          fontFamily="'CentraNo2', sans-serif"
                          fontWeight="700"
                          fontSize="22px"
                        >
                          {judge.name}
                        </Text>
                      </div>
                      <div className="judge-item__title">
                        <Text
                          as="div"
                          fontFamily="'CentraNo2Book', sans-serif"
                          color="#5f5f5f"
                          fontSize="15px"
                          lineHeight="17px"
                        >
                          {judge.title}
                        </Text>
                      </div>
                      <div className="judge-item__company">
                        <Text
                          as="div"
                          fontFamily="'CentraNo2', sans-serif"
                          fontWeight="700"
                          lineHeight="17px"
                          color="#5f5f5f"
                          fontSize="15px"
                        >
                          {judge.company}
                        </Text>
                      </div>
                    </div>
                  ))}
                </div>
              </Section>
            )}

          <Section
            name="pastHonoree"
            maxWidth={860}
            position={3}
            onWayPointEnter={onWayPointEnter}
            slug={slugify('past honoree testimonials')}
          >
            <div className="sectionHeading">
              <IconTitle title="Past Honoree Testimonials" />
            </div>
            <Text as="div">
              <div
                dangerouslySetInnerHTML={{
                  __html: pastHonoreesTestimonialsText
                }}
              />
            </Text>
          </Section>

          <Section
            name="finalApply"
            maxWidth={860}
            position={4}
            onWayPointEnter={onWayPointEnter}
            slug={slugify('apply now')}
          >
            {acceptingApplications && (
              <a href={packageApplyUrl}>
                <Button
                  bgColor="#004FDF"
                  labelHoverColor="#ffffff"
                  rounded
                  label={packageButtonText}
                />
              </a>
            )}
            <div className="deadlineSubText">
              <Text as="h6" color="#000000">
                {packageDeadline}
              </Text>
            </div>
          </Section>

          <Section name="carousel" maxWidth={860}>
            {/* <Carousel quote>
              {quotes.map((quote, i) => (
                <div className="carouselQuote" key={`quote=${i}`}>
                  <div className="quote">{quote.quote}</div>
                  <div className="name">{quote.name}</div>
                  <div className="caption">{quote.caption}</div>
                </div>
              ))}
            </Carousel> */}
            <div className="quotesWrapper">
              {quotes.map((quote, idx) => (
                <Fragment>
                  <Text as="div">
                    <div
                      className={`package_eligibility_blurb  quote_${idx}`}
                      dangerouslySetInnerHTML={{
                        __html: get(quote, 'quote', '#')
                      }}
                    />
                  </Text>
                  <div className="deadlineSubText">
                    <Text as="h3" color="#000000">
                      {get(quote, 'name')}
                    </Text>
                    <Text as="h6" color="#000000">
                      {get(quote, 'caption')}
                    </Text>
                  </div>
                </Fragment>
              ))}
            </div>
          </Section>

          <Section
            addClass="backToTop"
            name="ibd_anniversary"
            bgColor="#FFFFFF"
            maxWidth={300}
          >
            <a
              href=""
              onClick={event => {
                event.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <Text centered color="#000000">
                BACK TO TOP
              </Text>
            </a>
          </Section>
          <Footer />
        </div>
      </div>
    </Ibd2022Layout>
  );
};

// IbdApplyPage.defaultProps = {
//   status: 200
// };

// IbdApplyPage.propTypes = {
//   franchisePage: PropTypes.shape({
//     isLoading: PropTypes.bool,
//     error: PropTypes.bool,
//     errorMessage: PropTypes.string
//   })
// };

export default withRouter(IbdApplyPage);
